import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EmployeeOffcanvas from '../../../constant/EmployeeOffcanvas';
import EditEmployeeOffcanvas from '../../../constant/EditEmployeeOffcanvas';
import UbytovatInEmployee from '../../../constant/UbytovatInEmployeeOffcanvas';
import ZamestnatInEmployee from '../../../constant/ZamestnatInEmployeeOffcanvas';
import { setEmplInWorkData } from '../../../../store/actions/EmployeesInWorkActions';
import Pagination from './Pangination';
import { CSVLink } from 'react-csv';


const EmployeesTableList = ({setEventsUpdateTrigger}) => {
  const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
  const [dataUpdateTrigger, setDataUpdateTrigger] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null); 
  
  const [ActiveCompany, setActiveCompany] = useState('');
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [CheckBoxEmployees, setCheckBoxEmployees] = useState([]);

  const [searchValue, setSearchValue] = useState('');


  const filterInputRef = useRef(null);
  
  const employe = useRef();
  const editEmploye = useRef();
  const zamestnat = useRef();
  const ubytovat = useRef();
  const dispatch = useDispatch();
  
  const [employees, setEmployees] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 21;
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);
  const paginate = pageNumber => setCurrentPage(pageNumber);
 
  useEffect(() => {

    fetchData99();
  }, []);

  useEffect(() => {
    console.log('currentEmployees', currentEmployees);
    fetchData99();
  }, [firstCompany, dataUpdateTrigger]);

 

  const addEvmpFromCh = (newEmployee) => {
    console.log('newEmployee', newEmployee);
    if (!CheckBoxEmployees.includes(newEmployee)) {
      setCheckBoxEmployees((prevEmployees) => [...prevEmployees, newEmployee]);
    }
  };

  const removeEmployee = (emailToRemove) => {
    setCheckBoxEmployees((prevEmployees) => prevEmployees.filter(email => email !== emailToRemove));
  };

  const handleCheckboxChange = (email, isChecked) => {
    if (isChecked) {
      addEvmpFromCh(email);
    } else {
      removeEmployee(email);
    }

    
    // setCheckBoxEmployees((prevEmployees) => {
      
    //   if (isChecked && selectAllChecked) {
    //     return filteredEmployees.map((employee) => employee.email);
    //   }

      
    //   return isChecked
    //     ? [...prevEmployees, email]
    //     : prevEmployees.filter((selectedEmail) => selectedEmail !== email);
    // });
  };

  const handleSelectAll = (isChecked) => {
    setSelectAllChecked(isChecked);
    setCheckBoxEmployees(isChecked ? filteredEmployees.map((employee) => employee.email) : []);
  };
  
  const clearAllCheckboxes = () => {
    setSelectAllChecked(false);
    setCheckBoxEmployees([]);
  };

  const fetchData99 = async () => {
    try {
     
      console.log('firstCompany__fetchData99 -- EmployeesTableList.js', firstCompany);
       const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
      
      const data = await response.json();

      data.sort((a, b) => {
        const nameA = a.name.trim();
        const nameB = b.name.trim();
        return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
      });
      setEmployees(data);
      setFilteredEmployees(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const handleDelete = async (email) => {
    try {
     
      const response = await fetch('/delete_employee.php', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, firstCompany }), 
      });

      
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');

      }

      const employeeIndex = employees.findIndex((employee) => employee.email === email);
      if (employeeIndex === -1) {

        return;
      }

      const updatedEmployees = [...employees];
      updatedEmployees.splice(employeeIndex, 1);
      setEmployees(updatedEmployees);
      setDataUpdateTrigger(prev => !prev);

    } catch (error) {
      console.error('Ошибка при удалении сотрудника:', error);
      console.log('Ошибка при удалении сотрудника:');
      console.log(error);
    }
  };

  const handleEdit = (employee) => {
    setSelectedEmployee(employee); 
    editEmploye.current.showEditEmployeModal();
  };

  const numberOfEmployees = employees.length;

  useEffect(() => {

    dispatch(setEmplInWorkData(numberOfEmployees));
  }, [employees, dispatch]);  



  function createDeletingPopUp(employeeEmail) {
    var overlay = document.createElement("div");
    overlay.className = "overlay";

    var popup = document.createElement("div");
    popup.className = "popup";

    var buttonspopup = document.createElement("div");
    buttonspopup.className = "buttonspopup";

    var text = document.createElement("p");
    text.textContent = "Opravdu chcete zaměstnance trvale smazat ?";

    var logoPoUp = document.createElement("img");
    logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
    logoPoUp.classList.add('logoPoUp');

    var yesButton = document.createElement("button");
    yesButton.classList.add('btn', 'btn-primary');
    yesButton.textContent = "Ano";
    yesButton.addEventListener("click", function () {
  
      handleDelete(employeeEmail);
      closePopUp(overlay, popup);
    });

    var cancelButton = document.createElement("button");
    cancelButton.classList.add('btn', 'btn-danger', 'shadow');
    cancelButton.textContent = "Zrušit";
    cancelButton.addEventListener("click", function () {
      closePopUp(overlay, popup);
    });

    popup.appendChild(logoPoUp);
    popup.appendChild(text);
    buttonspopup.appendChild(yesButton);
    buttonspopup.appendChild(cancelButton);
    popup.appendChild(buttonspopup);
    document.body.appendChild(overlay);
    document.body.appendChild(popup);

    setTimeout(() => {

    }, 20000);

 
  }

  function closePopUp(overlay, popup) {
    overlay.remove();
    popup.remove();
  
  }

  

  function sortNepracuje() {
    const filtered = employees.filter(employee => employee.stredisko === '-' || employee.stredisko === null || employee.stredisko === '' || employee.stredisko === 'null');
    setFilteredEmployees(filtered);
  };

  function sortNeubytovan() {
    const filtered = employees.filter(employee => employee.ubytovna === '-' || employee.ubytovna === null || employee.ubytovna === '' || employee.ubytovna === 'null');
    setFilteredEmployees(filtered);
  };

  function sortBezpokoje() {
    const filtered = employees.filter(employee => employee.room === '-' || employee.room === null || employee.room === '' || employee.room === 'null');
    setFilteredEmployees(filtered);
  }
  

  function sortVsechno() {
  const filtered = employees;
  setFilteredEmployees(filtered);
};

  const handleSelectChange = (event) => {
    setCurrentPage(1);
    if (event.target.value === "vsechno") {
      sortVsechno();
    }
    if (event.target.value === "Neubytovan") {
      sortNeubytovan();
    }
    if (event.target.value === "Nepracuje") {
      sortNepracuje();
    }
    if (event.target.value === "Bez pokoje") {
      sortBezpokoje();
    }
  };

  const employeeTableRef = useRef(null);

  const handleInputChange = () => {
    setCurrentPage(1);
    const searchValue = filterInputRef.current.value.toLowerCase();
    const filtered = employees.filter(employee =>
      employee.name.toLowerCase().includes(searchValue)
    );
    setFilteredEmployees(filtered);
     
    console.log('filtered', filtered);
    console.log('currentEmployees2', currentEmployees);
  };

  

  const onUbytovatButton = () => {
    if (CheckBoxEmployees.length === 0) {
      alert("Vyberte pracovníky");
      return;
    }
  
    let hasAlerted = false; // Флаг для отслеживания вызова alert
  
    CheckBoxEmployees.forEach((email) => {
      if (hasAlerted) {
        return; 
      }
  
      const foundEmployee = employees.find((employee) => employee.email === email);
  
      if (
        foundEmployee &&
        foundEmployee.ubytovna !== undefined &&
        foundEmployee.ubytovna !== null &&
        foundEmployee.ubytovna !== '-'
      ) {
        console.log(`Employee with email ${email} has stredisko: ${foundEmployee.ubytovna}`);
  
        alert('Vyberte pracovníky bez ubytovny');
        hasAlerted = true; // Устанавливаем флаг в true после первого alert
        return; 
      }
  
      ubytovat.current.showUbytovatModal()
    }); 
  }; 
  

  const onZamestnatButton = () => {
    if (CheckBoxEmployees.length === 0) {
      alert("Vyberte pracovníky");
      return;
    }
  
    let hasAlerted = false; // Флаг для отслеживания вызова alert
  
    CheckBoxEmployees.forEach((email) => {
      if (hasAlerted) {
        return; // Если уже был вызван alert, выходим из цикла
      }
  
      const foundEmployee = employees.find((employee) => employee.email === email);
  
      if (
        foundEmployee &&
        foundEmployee.stredisko !== undefined &&
        foundEmployee.stredisko !== null &&
        foundEmployee.stredisko !== '-'
      ) {
        console.log(`Employee with email ${email} has stredisko: ${foundEmployee.stredisko}`);
  
        alert('Vyberte pracovníky bez střediska');
        hasAlerted = true; 
        return; 
      }
  
      zamestnat.current.showZamestnatModal();
    }); 
  };  
  
// excel export:

const csvlink = {
  data : employees,
  filename: "Zaměstnanci - Jobsi export.csv"
}


// END excel export
  


  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div className="tbl-caption">
              <h4 className="heading mb-0 heading-img">Pracovníky - {filteredEmployees.length}</h4>
              <input
        type="text"
        ref={filterInputRef}
        placeholder="začněte psat jméno"
        className="NameSearchInput"
        onChange={handleInputChange}
      />

              <div className="table_filter-sort">
                <p>Filtrovat:</p>
                <select className="sortSelectEmploye" onChange={handleSelectChange}>
                  <option value="vsechno">Všechno</option>
                  <option value="Neubytovan">Neubytovan</option>
                  <option value="Nepracuje">Nepracuje</option>
                  <option value="Bez pokoje">Bez pokoje</option>
                </select>

              </div>
              <div className='displayF cssForMobEt'>

                 <div style={{ height: 'fitContent'}}>
                                            <CSVLink {...csvlink} className="btn btn-primary light btn-sm csvLink">
                                                <i className="fa-solid fa-file-excel" /> {" "} 
                                                Exportovat do Excelu
                                            </CSVLink>  
                                        </div> 

              <div className="div-addButton marginRight10">

                <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                  onClick={() => employe.current.showEmployeModal()}
                >+ Přídat pracovníky</Link> {" "}

              </div>
              <div className="div-addButton marginRight10">

                <Link to={"#"} className="btn btn-primary btn-sm blackButtonZ" data-bs-toggle="offcanvas"
                  onClick={() => onZamestnatButton()}
                >+ Zaměstnat</Link> {" "}

              </div>
              <div className="div-addButton"> 

                <Link to={"#"} className="btn btn-primary btn-sm greenButtonU" data-bs-toggle="offcanvas"
                  onClick={() => onUbytovatButton()}
                >+ Ubytovat</Link> {" "}

              </div>
              </div>
            </div>
            <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
              <table
                id="projects-tbl employeeTable"
                ref={employeeTableRef}
                className="table ItemsCheckboxSec dataTable no-footer mb-0"
              >
                <thead>
                  <tr>
                  <th style={{ textAlign: 'center'}} ><input
              type="checkbox"
              checked={selectAllChecked}
              onChange={(e) => handleSelectAll(e.target.checked)}
            /></th>
                    <th>Jméno</th>

                    <th>Ubytovna</th>
                    <th>Národnost</th>
                    <th>Telefon</th>
                    <th>Poznámky</th>
                    <th>Změny</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEmployees.map((employee, index) => (
                    <tr key={index}>

                      <td>
                      <td>
              <input
                type="checkbox"
                checked={CheckBoxEmployees.includes(employee.email)}
                onChange={(e) => handleCheckboxChange(employee.email, e.target.checked)}
              />
            </td>
                      </td>

                      <td>
                        <div className="products">

                          <div>
                            <h6>
                              <Link
                                to={`/employee/${employee.email}`}
                                data-bs-toggle="offcanvas cursor-pointer"
                                className='displayF'
                              >
                                <span className=''>
                                {employee.gender === 'Mužský' ? (
                                    <img className="width50px" src="/male.png" />
                                  ) : employee.gender === 'Ženský' ? (
                                    <img className="width50px" src="/female.png" />
                                  ) : employee.gender === 'Prosím vyberte' ? (
                                    <img className="width50px" src="/malefemale.png" />
                                  ) : null}
                                </span>
                                <span className='name_plus_work'>
                                <span className='fontSize15 tableEmployeeName'>{employee.name}</span>
                                {employee.stredisko !== "-" && employee.stredisko !== 'null' ? (
                                  <span className="badge badge-success green-pracuje light border-0 fontSize10">{employee.stredisko}</span>
                                ) : (
                                  <span className="badge badge-danger red-pracuje light border-0 fontSize10">Nepracuje</span>
                                )}
                                </span>
                              </Link>
                            </h6>
                                
                          </div>
                        </div>
                      </td>


                      <td> 
                        {employee.hasOwnProperty("ubytovna") ? (
                          employee.ubytovna !== "-" && employee.ubytovna !== "null" ? (
                            <span className={`tableUbytovna badge badge-success green-ubytovan light border-0 ${(employee.room && employee.room !== '-') ? '' : 'noRoomOrange'}`}>
                              {employee.ubytovna}
                              {(employee.room && employee.room !== '-' && employee.room !== 'null') ? `, č. ${employee.room}` : null}
                            </span>

                          ) : (
                            <span className="tableUbytovna badge badge-danger red-neubytovan light border-0">Neubytovan</span>
                          )
                        ) : (
                          <span className="tableUbytovna badge badge-danger red-neubytovan light border-0">Neubytovan</span>
                        )}
                      </td>

                      <td>
                        <span className='displayF  alignItems'>
                          <span className='marginRight10'>
                          {employee.country}
                          </span>
                        <span className=''>
                        {employee.country === "CZ" ? (
                          <img className="iconWidth" src="/cz.jpg" />
                        ) : employee.country === "UA" ? (
                          <img className="iconWidth" src="/ua.jpg" />
                        ) : employee.country === "PL" ? (
                          <img className="iconWidth" src="/pl.webp" />
                        ) : employee.country === "LT" ? (
                          <img className="iconWidth" src="/lt.jpg" />
                        ) : employee.country === "RO" ? (
                          <img className="iconWidth" src="/ro.jpg" />
                        ) : employee.country === "MD" ? (
                          <img className="iconWidth" src="/md.jpg" />
                        ) : employee.country === "SK" ? (
                          <img className="iconWidth" src="/sk.jpg" />
                        ) :  employee.country === "HU" ? (
                          <img className="iconWidth" src="/hu.jpg" />
                        ) : employee.country === "AZ" ? (
                          <img className="iconWidth" src="/az.jpg" />
                        ) : employee.country === "BG" ? (
                          <img className="iconWidth" src="/bgr.jpg" />
                        ) : employee.country === "LV" ? (
                          <img className="iconWidth" src="/lv.jpg" />
                        ) : null
                        }
                          </span>
                        </span>
                      </td>
                      <td>
                        <span>{employee.mobile}</span>
                      </td>

                      <td>
                        <span>{employee.note}</span>
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={"#"}
                            className="btn btn-primary shadow btn-xs sharp me-1" data-bs-toggle="offcanvas" onClick={() => handleEdit(employee)}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </Link>

                          <Link
                            to={"#"}
                            className="btn btn-danger shadow btn-xs sharp"
                            onClick={() => createDeletingPopUp(employee.email)} 
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
        employeesPerPage={employeesPerPage}
        totalEmployees={filteredEmployees.length}
        paginate={paginate}
      />
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas
        ref={employe}
        Title="Add Employee"
        setDataUpdateTrigger={setDataUpdateTrigger}
        setEventsUpdateTrigger={setEventsUpdateTrigger}
      />
      <EditEmployeeOffcanvas
        ref={editEmploye}
        Title="Edit Employee"
        setDataUpdateTrigger={setDataUpdateTrigger}
        setEventsUpdateTrigger={setEventsUpdateTrigger}
        selectedEmployee={selectedEmployee}
      />
      <ZamestnatInEmployee
        ref={zamestnat} 
        Title="Zamestnat"
        setDataUpdateTrigger={setDataUpdateTrigger}
        setEventsUpdateTrigger={setEventsUpdateTrigger}
        CheckBoxEmployees={CheckBoxEmployees}
        clearAllCheckboxes={clearAllCheckboxes}
      />
      <UbytovatInEmployee
        ref={ubytovat}
        Title="Ubytovat"
        setDataUpdateTrigger={setDataUpdateTrigger}
        setEventsUpdateTrigger={setEventsUpdateTrigger}
        CheckBoxEmployees={CheckBoxEmployees}
        clearAllCheckboxes={clearAllCheckboxes}
      />
    </>
  ); 
};


export default EmployeesTableList;