import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFirstCompany } from './.././../../store/actions/companyActions';
import { IMAGES, SVGICON } from "../../constant/theme";
import Logoutbtn from "./Logoutbtn";
import AddNewCompany from "../../constant/AddNewCompanyOffcanvas";
import LogoutPage from './Logout';
import { Dropdown } from "react-bootstrap";
import axios from 'axios';

const NotificationBlog =({refreshData}) => {
	const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
	const [notifications, setNotifications] = useState([]); 
	const [employees, setEmployees] = useState([]);
	const [mergedEmp, setMergedEpm] = useState([]);
	const [employeesWithHostelId, setEmployeesWithHostelId] = useState([]);

	useEffect(() => {
		// fetchNotifications();
		fetchEmployees();
	  }, [firstCompany, refreshData]); 

	  useEffect(() => {
		//  handleNotif();
		checkNotifNoHostel();
		console.log('refreshData - loaded', refreshData);
		console.log('employeesWithHostelId', employeesWithHostelId);
	  }, [firstCompany, employeesWithHostelId, refreshData]);

	  const fetchEmployees = async () => {
		try {
			const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
			const data = await response.json();
		//   setEmployees(data);

		  if (data.length > 0 ) {
			const merged = data
				.map(employee => {
					if ('notification' in employee) {
						if (employee.notification.some(item => item.type === 'noWork' && 'notToLoad' in item)) {
							console.log('Ключ "notToLoad" присутствует в массиве notification');
						} else {
							console.log('Ключ "notToLoad" отсутствует в массиве notification');
					console.log('employee - notif', employee);
					const currentTime = new Date();
				
					const notificationTime = new Date(employee.notification[0].time);
					

					
					console.log('map - notificationTime', notificationTime);

					const twoHoursAgo = new Date(currentTime.getTime() - (20 * 1000));
					console.log('map - twoHoursAgo', twoHoursAgo);
					if (currentTime.getTime() >= twoHoursAgo.getTime() && notificationTime.getTime() <= twoHoursAgo.getTime()) {						
						if (employee && (employee.hostelId === "-" || employee.hostelId === null || employee.hostelId === "")) {
							return { ...employee };
							
						} 
					} if (employee && (employee.hostelId != "-" || employee.hostelId != null || employee.hostelId != "")) {
						setEmployeesWithHostelId(prevState => [...prevState, employee]); 
						console.log('employeesWithHostelId--1', employeesWithHostelId);
							return null;
					}
				}

				}
				});
				console.log('setMergedEpm', merged);
				setMergedEpm(merged);
		}


		  console.log('setEmployees', data);
		} catch (error) {
		  console.error('Ошибка при загрузке данных:', error);
		}
	  };
	  
	const checkNotifNoHostel = async () => {
		console.log('employeesWithHostelId', employeesWithHostelId);
		if (employeesWithHostelId.length > 0) {
			const notifType = 'noHostel';
			let emails = {};
			employeesWithHostelId.forEach((employeeWhihID) => {
				emails.append('email', employeeWhihID.email);
			});

			try { 
				const url = `https://online.jobsi.app/update_notif.php`;
				const response = await axios.put(url, { emails, firstCompany, notifType });

        if (response.status === 200) {
          console.log('Данные успешно обновлены');
        }  else {
			// Обработка ошибок, если запрос вернул ошибку
			console.error('Ошибка при обновлении данных:', response.data.message);
		  }
			} catch (error) {
				console.error('Ошибка при выполнении запроса:', error);
			  } 
		}
	}

	// const handleNotif = async () => {  
	// 		console.log('employeesWithHostelId', employeesWithHostelId);
	// 		if (employeesWithHostelId.length > 0) {
	// 	try {
	// 	  const response = await fetch('/delete_notif.php', { 
	// 		method: 'DELETE',
	// 		headers: {
	// 		  'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify({employeesWithHostelId, firstCompany }), 
	// 	  });
	
	// 	  if (!response.ok) {
	// 		console.log('Не удалось удалить данные на сервере');
	// 		throw new Error('Не удалось удалить данные на сервере');
	
	// 	  }
	

	// 	} catch (error) {
	// 	  console.error('Ошибка при удалении сотрудника:', error);
	// 	  console.log(error);
	// 	}
	// 		}
	//   };
	
	

	  return ( 
		<>
		  {mergedEmp && mergedEmp.map((employee, index) => (
      employee && employee.notification && employee.notification.length > 0 && ( 
        <li key={index}>
          <div className="timeline-panel"> 
            <div className="media-body">
              <h6 className="mb-1">{employee.name} - {employee.notification[0].description}</h6> {/* Обращение к первому элементу массива notification */}
              <small className="d-block">
                {(() => {
                  const date = new Date(employee.notification[0].time);
                  const month = (date.getMonth() + 1).toString().padStart(2, '0');
                  const day = date.getDate().toString().padStart(2, '0');
                  const year = date.getFullYear();
                  const hours = date.getHours().toString().padStart(2, '0');
                  const minutes = date.getMinutes().toString().padStart(2, '0');
                  const seconds = date.getSeconds().toString().padStart(2, '0');
                  return `${month}.${day}.${year} ${hours}:${minutes}:${seconds}`;
                })()}
              </small>
            </div>
          </div>
        </li>
      )
    ))}
  </>
	  );
	  
	  
	  
}

const Header = ({ onNote }) => {
	const [headerFix, setheaderFix] = useState(false);
	const [LoginUser, setLoginUser] = useState([]);
	const [CompanyData, setCompanyData] = useState([]);
	const [firstCompany, setFirstCompanyState] = useState('');
	const [RegistrCompanyName, setRegistrCompanyName] = useState('');
	const [Companies, setCompanies] = useState([]);
	const [dataUpdateTrigger, setDataUpdateTrigger] = useState(false);
	const [refreshData, setRefreshData] = useState(false);

    const handleRefreshData = () => {
        setRefreshData(!refreshData);
    };
	
	const dispatch = useDispatch();

	// Получаем значение куки "user_id"
	var userId = document.cookie.replace(/(?:(?:^|.*;\s*)user_id\s*\=\s*([^;]*).*$)|^.*$/, "$1");

	console.log(userId);

	const firstActiveCompany = async (newCompany) => {		
		console.log('firstActiveCompany__newCompany - header.js', newCompany)
	  dispatch(setFirstCompany(newCompany));
	};


	useEffect(() => {
		fetchDataAccount();
	  }, [dataUpdateTrigger]);

	
	const addNewCompany = useRef();


	const fetchDataAccount = async () => {
		try {
		  const response = await fetch(`https://online.jobsi.app/registered.json?${Date.now()}`);
	
		  const userData = await response.json();
	
		  const foundUser = userData.find((user) => user.email === userId);
	
		  if (foundUser) {
			setLoginUser(foundUser);
			setFirstCompanyState(foundUser.company); 
			const companyName = foundUser.company.split('_')[0];
			setRegistrCompanyName(companyName);
			console.log("companyName", companyName);
			console.log("foundUser.company", foundUser.company);
			
			firstActiveCompany(foundUser.company);
			console.log('foundUser.companies', foundUser.companies);
			setCompanies(foundUser.companies);
		  }
		} catch (error) {
		  console.error('Error fetching data:', error);
		}
	  };

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
		fetchDataAccount();
		// fetchDataCompanys();
	}, []); 
	
  
  return ( 
	<>
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          	<div className="collapse navbar-collapse headerMenu1">
			
				<div className="Companys">
				
					<select value={firstCompany} onChange={(e) => {
					console.log('LoginUser', LoginUser);
					// updateActiveCompany(e.target.value);
					console.log("e.target.value", e.target.value);
					// setCookie("activeCompany", e.target.value, 1);
					firstActiveCompany(e.target.value)
					setFirstCompanyState(e.target.value);
					if (e.target.value === "addCompany") {
						addNewCompany.current.showAddNewCompanyModal();
					}
                	}}>
					{LoginUser && LoginUser.company && (
						<option selected value={LoginUser.company}>{RegistrCompanyName}</option>
					)}
					{Companies && (
						Object.keys(Companies).map((companyName) => {
							const companyNameWithoutSuffix = companyName.split('_')[0];
							return (
							<option key={companyName} value={Companies[companyName]}>
								{companyNameWithoutSuffix}
							</option>
							);
						})
						)}

					<option value="addCompany">+ Přidat pobočku</option>
					</select> 




				</div>
				<ul className="navbar-nav header-right">			
					
					 <Dropdown as="li" className="nav-item dropdown notification_dropdown" onClick={handleRefreshData}>
						<Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
							</svg>							
						</Dropdown.Toggle>
						<Dropdown.Menu align="end" className="mt-2 dropdown-menu dropdown-menu-end">
							<div className="widget-media dz-scroll p-3 height380">
								<ul className="timeline">
									 <NotificationBlog classChange='media-info' refreshData={refreshData} />
								</ul>
							<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }}/>
							</div>
							<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }}/>
							</div>
						</div>
						<Link className="all-notification" to="#">
						Zobrazit všechna oznámení <i className="ti-arrow-right" />
						</Link>
					</Dropdown.Menu>
					</Dropdown> 
					 <Dropdown as="li" className="nav-item dropdown notification_dropdown ">
						<Dropdown.Toggle variant="" as="a" className="nav-link  i-false c-pointer" onClick={() => onNote()}>
							<svg width="20" height="22" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M16.9026 6.85114L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59922 10.4642L5.11844 6.85114" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>							
						</Dropdown.Toggle>
					</Dropdown>						
					<li className="nav-item align-items-center header-border">
						<Logoutbtn />
					</li>
					 <li className="nav-item ps-3">
						<Dropdown className="header-profile2">
							<Dropdown.Toggle className="nav-link i-false" as="div">
								<div className="header-info2 d-flex align-items-center">
									<div className="header-media">
										<img src={IMAGES.Tab1} alt="" />
									</div>
									<div className="header-info">
										<h6>{LoginUser.firstName}</h6>
										<p><h6>{LoginUser.email}</h6></p>
									</div>
									
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu align="end">
								<div className="card border-0 mb-0">
									<div className="card-header py-2">
										<div className="products">
											<img src={IMAGES.Tab1} className="avatar avatar-md" alt="" />
											<div>
												<h6>{LoginUser.firstName}</h6>
												<span>{LoginUser.email}</span>	
											</div>	
										</div>
									</div>
									<div className="card-body px-0 py-2">
										<Link to={"/app-profile"} className="dropdown-item ai-icon ">
											{SVGICON.User}{" "}
											<span className="ms-2">Profil </span>
										</Link>
										<Link to={"/app-profile"} className="dropdown-item ai-icon ">
											{SVGICON.Project}{" "}												
											<span className="ms-2">Moje pobočky</span>
											{/* <span className="badge badge-sm badge-secondary light rounded-circle text-white ms-2">4</span> */}
										</Link>
										<Link to={"#"} className="dropdown-item ai-icon ">
											{SVGICON.Message}{" "}
											<span className="ms-2">Zprávy </span>
										</Link>
										<Link to={"/email-inbox"} className="dropdown-item ai-icon ">
											{SVGICON.Notification} {" "}
											<span className="ms-2">Notifikace </span>
										</Link>
									</div>
									<div className="card-footer px-0 py-2">
										<Link to={"#"} className="dropdown-item ai-icon ">
											{SVGICON.Headersetting} {" "}
											<span className="ms-2">Nastavení </span>
										</Link>										
										<LogoutPage />
									</div>
								</div>
								
							</Dropdown.Menu>
						</Dropdown>
					</li>						
				</ul>
			
			
          </div>
        </nav>
      </div>
    </div>
	<AddNewCompany
	ref={addNewCompany}
	Title="AddNewCompany"
	
	LoginUser={LoginUser}
	setDataUpdateTrigger={setDataUpdateTrigger}
  />
  </>
  );
};

export default Header;
