import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef  } from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';


const EmployeeOffcanvas = forwardRef((props, ref) => {
     const firstCompany = useSelector(state => (state.company && state.company.firstCompany) || '');
    const [hostelsData, setHostels] = useState([]);
    const [tovarnyData, setTovarny] = useState([]);
    const [selectedSelect, setSelectedSelect] = useState(null);
    const [selectedTovarna, setSelectedTovarna] = useState(null);
    const [Rooms, setRooms] = useState([]);
    const [RoomsCapacity, setRoomsCapacity] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [selectedHostelId, setSelectedHostelId] = useState('')
    const [selectedTovarnaId, setSelectedTovarnaId] = useState('')
    const [email, setEmail] = useState('');
    const [serverEmails, setServerEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    
    const generateRandomEmail = () => {
      let randomEmail;
      do {
        randomEmail = `${Math.random().toString(36).substring(2, 10)}@example.com`;
      } while (serverEmails.includes(randomEmail)); // Проверяем, что email не существует на сервере
      setEmail(randomEmail);
    };
  
   
    const handleButtonClick = (e) => {
      e.preventDefault();

        generateRandomEmail();    
    };

  
  

    useEffect(() => { 
    fetchData88();
    fetchDataTovarny();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, [firstCompany]);

  const fetchData88 = async () => {
   
    try {
      const response = await fetch(`/${firstCompany}/ubytovny.json?${Date.now()}`);
      const data = await response.json();
      setHostels(data);
      console.log('EmployeeOffCan - data', data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const fetchDataTovarny = async () => {
    // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
    try {
      const response = await fetch(`/${firstCompany}/tovarny.json?${Date.now()}`);
      const data = await response.json();
      setTovarny(data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

    const { setDataUpdateTrigger, setEventsUpdateTrigger } = props;
    const [startDate, setStartDate] = useState(new Date());
    const [startDate2, setStartDate2] = useState(new Date());
    const [addEmploye, setAddEmploye] = useState(false);

    


    const showEditEmployeModal = () => {
      setAddEmploye(true);
    };
  
    useImperativeHandle(ref, () => ({
      showEmployeModal() {
        setAddEmploye(true);
      },
      showEditEmployeModal, // Передаем метод в ref
    }));
  
    const nav = useNavigate();
   
    const handleSubmit = async (e) => {
      e.preventDefault(); 
      if (checkGenderSelection()) {
        return;
      }
      console.log(' EmployeeOffcanvas -- hostelName 0', selectedSelect);
      console.log(' EmployeeOffcanvas -- tovarnaName 0', selectedTovarna);

      const hostelNameToAdd = selectedSelect === null ? '-' : selectedSelect;
      const tovarnaNameToAdd = selectedTovarna === null ? '-' : selectedTovarna;


      const formData = new FormData(e.target);
      formData.append('firstCompany', firstCompany);
      
      
      formData.append('ubytovna', selectedHostelId);
      formData.append('hostelName', hostelNameToAdd);
      formData.append('tovarnaName', tovarnaNameToAdd);
      formData.append('gender', selectedGender); 
      formData.append('country', country);
      formData.append('stredisko', selectedTovarnaId);
      formData.append('pokoj', selectedPokoj);

      if (tovarnaNameToAdd === '-' || tovarnaNameToAdd === '' || tovarnaNameToAdd === 'null' || tovarnaNameToAdd === null) {
        const currentTime = new Date(); 
        const formattedTime = currentTime.toISOString(); 
        console.log('currentTime', currentTime);
        // formData.append('noWork', currentTime);
        // formData.append('description', 'Pracovník byl vytvořen, ale během 2 dnů nebyl zaměstnaný');
        const noWork = {
          noWork: currentTime,
          description: 'Pracovník byl vytvořen, ale během 2 dnů nebyl zaměstnaný'
        };
        formData.append('noWork', JSON.stringify(noWork));
    }

    if (hostelNameToAdd === '-' || hostelNameToAdd === '' || hostelNameToAdd === 'null' || hostelNameToAdd === null) {
      const currentTime = new Date(); 
      const formattedTime = currentTime.toISOString();  
      console.log('currentTime', currentTime);
      // formData.append('noHotel', currentTime);
      // formData.append('description', 'Pracovník byl vytvořen, ale během 2 dnů nebyl umístěn do hotelu');
      const noHotel = {
        noHotel: currentTime,
        description: 'Pracovník byl vytvořen, ale během 2 dnů nebyl umístěn do hotelu'
      };
      formData.append('noHotel', JSON.stringify(noHotel));
  } 
    


      console.log(' EmployeeOffcanvas -- formData.get(noHotel); ', formData.get('noHotel'));
      console.log(' EmployeeOffcanvas -- tovarnaName', selectedTovarna);
      console.log('formData -- EmployeeOffcanvas', formData);
      console.log('firstCompany -- EmployeeOffcanvas', firstCompany);



      try {
        // Загрузка данных из file.json
        const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
        const data = await response.json();
        
        // Проверка на уникальность email
        const email = formData.get('email'); 
        const isEmailUnique = data.every(item => item.email !== email);
  
        if (!isEmailUnique) {
          createDeletingPopUpPokoj('Email již existuje. Zadejte prosím jiný e-mail.');
          return;
        }

        const mobile = formData.get('mobile');

        const isMobileUnique = data.every(item => item.mobile !== mobile);
  
        if (!isMobileUnique) {
          createDeletingPopUpPokoj('Telefonní číslo již existuje. Zadejte prosím jiný.');
          return;
        } 
    
        // Отправка данных на сервер
        axios.post('/save_data.php', formData) 
          .then(response => {
            console.log('save_data');
            setDataUpdateTrigger((prev) => !prev);
            setEventsUpdateTrigger((prev) => !prev);
            
            
          })
          .catch(error => {
            console.error(error);
          });

          
  
        setAddEmploye(false);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };
      
    



    const fetchDataPokoje = async () => {
      // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
        try {
          const response = await fetch(`/${firstCompany}/pokoje.json?${Date.now()}`);
          const data = await response.json();

          const response2 = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data2 = await response2.json();

          const filteredRooms = data.filter(
            (room) =>
              room.ubytovna === selectedSelect 
          );

          console.log('filteredRooms', filteredRooms);

          const roomCount = {};

      data2.forEach((client) => {
        if (client.ubytovna === selectedSelect) {
          const key = `${client.ubytovna} *-* ${client.room}`;
          if (!roomCount[key]) {
            roomCount[key] = 1;
          } else {
            roomCount[key]++;
          }
        }
      });

      console.log('roomCount', roomCount);
    
      const numberOfKeys = Object.keys(roomCount).length;

      if (numberOfKeys > 0) {
        
      const roomCountArray = Object.keys(roomCount).map((key) => {
        const [ubytovna, room] = key.split(' *-* ');
        return { ubytovna, room, count: roomCount[key] };
      });

      console.log('1-roomCountArray', roomCountArray);

      const roomsWithFreeSpace = filteredRooms.map((room) => {
        const countObj = roomCountArray.find((obj) => obj.ubytovna === room.ubytovna && obj.room === room.id);
        if (countObj) {
            const quantity = parseInt(room.quantity, 10);
            const count = countObj.count;
            const freeSpace = quantity - count;
            return { ...room, freeSpace };
        } else {
            // Если не удалось найти соответствующий объект в roomCountArray, то считаем, что свободных мест нет.
            return { ...room, freeSpace: room.quantity };
        }
    });
    
    console.log('roomsWithFreeSpace', roomsWithFreeSpace);

    const RoomsWithSpace = roomsWithFreeSpace.filter((room) => room.freeSpace > 0);

    console.log('RoomsWithSpace', RoomsWithSpace);

  setRooms(RoomsWithSpace);
} else {
  setRooms(filteredRooms);
  console.log('filteredRooms-filteredRooms',filteredRooms);
}
          // setRooms(data);
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };
     


      const fetchDataFile = async () => {
        try {
          // var activeCompany = document.cookie.replace(/(?:(?:^|.*;\s*)activeCompany\s*\=\s*([^;]*))/, "$1");
          const response = await fetch(`/${firstCompany}/file.json?${Date.now()}`);
          const data = await response.json();
      
          
          const roomCounts = {};
          const emailList = data.map(item => item.email);
           
          setServerEmails(emailList);
          

          // Подсчитываем количество объектов с одинаковыми значениями ключа "room"
          data.forEach(obj => {
            if (obj.hasOwnProperty('room')) {
              const roomValue = obj.room;
              roomCounts[roomValue] = (roomCounts[roomValue] || 0) + 1;
            }
          });
      
           setRoomsCapacity(roomCounts);
      
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
        }
      };


      useEffect(() => {
        
        fetchDataPokoje();
        fetchDataFile();
      }, []);


      useEffect(() => {
       
        fetchDataPokoje();
        setTimeout(() => {
          console.log('Rooms1', Rooms);
        },1000);
      }, [selectedSelect]);
    
      

    // select tovarna :
    const [isOpenTovarna, setIsOpenTovarna] = useState(false);
  
    const handleTovarnaSelect = (tovarna) => {
      setSelectedTovarnaId(tovarna.id);
      setSelectedTovarna(tovarna.name);
      setIsOpenTovarna(false); // Close dropdown after selection
    };

    // END select tovarna

      
// Select State:

const [isOpen, setIsOpen] = useState(false);
const [country, setCountry] = useState('');

    const [selectedValue, setSelectedValue] = useState({ label: 'Prosím vyberte', icon: '' });
    const selectRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (value, label, icon) => {
        setSelectedValue({ label, icon });
        setCountry(value);
        setIsOpen(false);
        
    };

// Select Pohlavi END

// Select Ubytovna:

const handleUbytovnaSelect = (selectedHostelName) => {
  const selectedHostel = hostelsData.find(hostel => hostel.id === selectedHostelName);
  setSelectedHostelId(selectedHostel ? selectedHostel.id : '');
  setSelectedSelect(selectedHostel.name);
  setSelectedPokoj('-');
};

const [showOptions, setShowOptions] = useState(false);


// END  Select Ubytovna 


// Select Pokoj

const [showOptionsPokoj, setShowOptionsPokoj] = useState(false);
const [selectedPokoj, setSelectedPokoj] = useState('')

const handlePokojSelect = (selectedRoomId) => {
  setSelectedPokoj(selectedRoomId);
    console.log(`Выбрана комната с id: ${selectedRoomId}`);
    
};

// END Select Pokoj

// Pohlavi: 


const [selectedGender, setSelectedGender] = useState(null);

const handleGenderChange = (gender) => {
  setSelectedGender(gender);
};

const checkGenderSelection = () => {
  if (!selectedGender) {
    alert("Vyberte prosím pohlaví.");
    return true; 
  }
  return false; 
};
// Pohlavi END
  

function createDeletingPopUpPokoj(neededText) {
    
  var overlay = document.createElement("div");
  overlay.className = "overlay";

  var popup = document.createElement("div");
  popup.className = "popup"; 

  var buttonspopup = document.createElement("div");
  buttonspopup.className = "buttonspopup";

  
  var text = document.createElement("p");
  text.textContent = neededText;

  var logoPoUp = document.createElement("img");
  logoPoUp.src = '/static/media/Logo%20OWB%20website.f8178ceeb0449f70be9f.png';
  logoPoUp.classList.add('logoPoUp');
  
  var yesButton = document.createElement("button");  
  yesButton.classList.add('btn', 'btn-primary');
  yesButton.textContent = "Zavřít";
  yesButton.addEventListener("click", function () {
    //  удаление
    closePopUp(overlay, popup);
  });


  
  popup.appendChild(logoPoUp);
  popup.appendChild(text);
  buttonspopup.appendChild(yesButton);
  popup.appendChild(buttonspopup);
  document.body.appendChild(overlay);
  document.body.appendChild(popup);


}

function closePopUp(overlay, popup) {
  overlay.remove();
  popup.remove();
  // clearTimeout(timerPopUp);
}

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
              
					<h5 className="modal-title" id="#gridSystemModal">Nový zaměstnanec</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                            
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput2" className="form-label">Jméno <span className="text-danger">*</span></label>
                                    <input type="text" name="name" className="form-control" id="exampleFormControlInput2" required placeholder="" />
                                </div>	
                                <div className="col-xl-6 mb-3 hideInputEmail">
                                  <div className="display-flex" >
                                    <label htmlFor="exampleFormControlInput3" className="form-label">Email <span className="text-danger">*</span></label>
                                    <a className="btn btn-primary btn-sm" onClick={handleButtonClick}>neni</a>
                                    </div>
                                    <input
        type="email"
        name="email"
        className="form-control"
        id="exampleFormControlInput3"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
                                </div> 
                               
                                <div className="col-xl-6 mb-3 DFlex">
                                  <div style={{ width: '65%', position: 'relative' }}>
                                    <label className="form-label">Národnost <span className="text-danger">*</span></label>
                                   <div ref={selectRef} className="default-select default-select2 form-control" style={{ paddingLeft: '15px' }}>
            <div className="select-selected" onClick={() => setIsOpen(!isOpen)}>
                {selectedValue.label} {selectedValue.icon && <img className="icon" src={selectedValue.icon} alt="icon" />}
                <img src="/arrow-bottom-white.png" style={{ width: '20px' }}></img>
            </div>
            {isOpen && (
                <div className="select-items">
                    <div className="select-items_div" onClick={() => handleSelect('CZ', 'CZ', '/cz.jpg')}>
                        <p>CZ</p> <img className="icon" src="/cz.jpg" alt="CZ icon" />
                    </div>
                    <div className="select-items_div" onClick={() => handleSelect('UA', 'UA', '/ua.jpg')}><p>UA</p> <img className="icon" src="/ua.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('PL', 'PL', '/pl.webp')}><p>PL</p> <img className="icon" src="/pl.webp" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('LT', 'LT', '/lt.jpg')}><p>LT</p> <img className="icon" src="/lt.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('LV', 'LV', '/lv.jpg')}><p>LV</p> <img className="icon" src="/lv.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('RO', 'RO', '/ro.jpg')}><p>RO</p> <img className="icon" src="/ro.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('MD', 'MD', '/md.jpg')}><p>MD</p> <img className="icon" src="/md.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('HU', 'HU', '/hu.jpg')}><p>HU</p> <img className="icon" src="/hu.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('AZ', 'AZ', '/az.jpg')}><p>AZ</p> <img className="icon" src="/az.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('SK', 'SK', '/sk.jpg')}><p>SK</p> <img className="icon" src="/sk.jpg" alt="CZ icon" /></div>
                    <div className="select-items_div" onClick={() => handleSelect('BG', 'BG', '/bgr.jpg')}><p>BG</p> <img className="icon" src="/bgr.jpg" alt="CZ icon" /></div>
                </div>
            )}
            
        </div>

                                    
                                    </div>

                                    <div style={{width: '30%'}}>
                                    <label className="form-label">Pohlaví <span className="text-danger">*</span></label>

                                    <div className='DFlex' style={{height: '2.813rem'}}>
    <div
      className={`gender-square gender-squareM  ${selectedGender === "Mužský" ? "selected" : ""}`}
      onClick={() => handleGenderChange("Mužský")} 
    >
      M 
    </div>

    <div
      className={`gender-square gender-squareZ ${selectedGender === "Ženský" ? "selected" : ""}`}
      onClick={() => handleGenderChange("Ženský")}
    >
      Ž
    </div>
  </div>
                                    

                                    </div>
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Telefon <span className="text-danger">*</span></label>
                                    <input name="mobile" type="number" className="form-control" id="exampleFormControlInput88" required placeholder="" />
                                </div>

                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput99" className="form-label">Datum registrace<span className="text-danger">*</span></label>                                    
                                    <input
      type="date"
      id="registr"
      name="registr"
      className="inputReg form-control"
      value={currentDate}
      onChange={(e) => setCurrentDate(e.target.value)}
    />
                                </div>
                                <div className="col-xl-6 mb-3">
                                    <label htmlFor="exampleFormControlInput8" className="form-label">Datum narození <span className="text-danger">*</span></label>                                    
                                    <input name="birth" type="date" className="form-control" id="exampleFormControlInput88" required placeholder="" />
                                </div>
                                

                                <div className="col-xl-6 mb-3 DFlex ubytovnaP">

                                <div style={{ width: '65%' }}>
    <label className="form-label">Ubytovna</label>
    <div className="default-select form-control ubytovnaInputDiv" style={{ position: 'relative' }}>
        <div
            className="selected-option"
            onClick={() => setShowOptions(!showOptions)}
        >
            {selectedSelect || "-"}
            <img src="/arrow-bottom-blue.png" style={{ width: '20px' }} alt="arrow"></img>
        </div>
        {showOptions && (
            <div className="options">
                {hostelsData.map((hostel, index) => (
                    <div
                        key={index}
                        className="option"
                        onClick={() => {
                            handleUbytovnaSelect(hostel.id);
                            setShowOptions(false);
                        }}
                    >
                       <span className='optionSpan'>{hostel.name}</span>
                    </div>
                ))}
                <div
                    className="option"
                    onClick={() => {
                      handleUbytovnaSelect('-'); 
                      setShowOptions(false);
                    }}
                >
                    -
                </div>
            </div>
        )}
    </div>
</div>



<div style={{ width: '30%' }}>
    <label className="form-label">Pokoj</label>
    <div className="default-select form-control pokojInputDiv" style={{ position: 'relative' }}>
        
        <div
            className="selected-option"
            onClick={() => setShowOptionsPokoj(!showOptions)}
        >
            {selectedPokoj !== null ? `${selectedPokoj}` : "-"}
            <img src="/arrow-bottom-blue.png" style={{ width: '20px' }}></img>
        </div>
        {showOptionsPokoj && (
            <div className="options">
                {Rooms.map((room, index) => (
                    <div
                        key={index}
                        className="option"
                        onClick={() => {
                            handlePokojSelect(room.id);
                            setShowOptionsPokoj(false);
                        }}
                    >
                        {room.name}
                    </div>
                ))}
                <div
                    className="option"
                    onClick={() => {
                        handlePokojSelect(null); // or whatever value you want to represent the dash
                        setShowOptionsPokoj(false);
                    }}
                >
                    -
                </div>
            </div>
        )}
    </div>
</div>


</div>



<div className="col-xl-6 mb-3 strediskoForm">
      <label className="form-label">Pracovní středisko</label>
      <div className="default-select form-control strediskoInputDiv">
        <div 
          className={`selected-option ${isOpenTovarna ? 'placeholder' : ''}`} 
          onClick={() => setIsOpenTovarna(!isOpenTovarna)}
        >
          {selectedTovarnaId ? selectedTovarna : 'Nepracuje'}
          <img src="/arrow-bottom-blue.png" style={{ width: '20px' }}></img>
        </div>
        {isOpenTovarna && (
          <div className="options">
            <div 
              className={`option ${selectedTovarnaId ? '' : 'selected'}`} 
              onClick={() => handleTovarnaSelect({ id: '', name: '-' })}
            >
              Nepracuje
            </div>
            {tovarnyData.map((tovarna, index) => (
              <div 
                key={index} 
                className={`option ${selectedTovarnaId === tovarna.id ? 'selected' : ''}`} 
                onClick={() => handleTovarnaSelect(tovarna)}
              >
                {tovarna.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>

      <div className="">
                                    <label htmlFor="exampleFormControlInput88" className="form-label">Poznámky</label>
                                    <input name="note" type="text" className="form-control" id="exampleFormControlInput88" placeholder="" />
                                </div>

                            </div>

                            <div style={{marginTop: '30px'}}>
                              <img style={{width: '100%'}} src="/Addworker.webp"></img>
                            </div>

                            <div style={{marginTop: '30px', marginBottom: '50px'}}>
                                <button type="submit" className="btn btn-primary me-1">Přidat</button>
                                <Link to={"#"} onClick={()=>setAddEmploye(false)} className="btn btn-danger light ms-1">Zrušit</Link>
                            </div>
                        </form>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default EmployeeOffcanvas;